<template>
  <div class="truck-step4">
    <div class="bg-white">
      <div class="pl-24">
        <label class="require ml-10 text-18 text-main font-500">人车合影照片</label>
      </div>
      <div class="owner-wrap d-flex justify-space-between px-24 py-8">
        <tos-uploader class="owner-card" v-model="file" :max-count="1" bgType="rencheheying"></tos-uploader>
      </div>
      <div class="text-note text-center mt-4">请保证照片中您的正脸、车牌号及车身清晰可见</div>
    </div>
    <van-form class="px-12" ref="form">
      <template v-for="(item, key) in FormObj">
        <van-field
          readonly
          @click="popShow = true"
          :required="item.rules[0].required"
          :value="truckRef[key]"
          input-align="right"
          :name="item.name"
          placeholder="请选择"
          rows="1"
          :key="key"
          :rules="item.rules"
          is-link
          v-if="key === 'axles'"
        >
          <template #label>
            <div :class="{ titleError: truckRef.reasonKeys.includes(key) }">
              <div>
                {{ item.label }}
              </div>

              <div class="font-12" v-if="truckRef.reasonKeys.includes(key)">
                不正确
              </div>
            </div>
          </template>
        </van-field>
      </template>
    </van-form>
    <van-popup v-model="popShow" position="bottom">
      <van-picker
        title="请选择"
        show-toolbar
        :columns="axlesOptions"
        value-key="text"
        @confirm="onAxlesConfirm"
        @cancel="popShow = false"
      />
    </van-popup>
    <div>
      <div class="truck-btn-box">
        <Button size="large" @click="handleToNext" type="primary">提交认证</Button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive, computed } from '@vue/composition-api';
import { useTruckVerifiedStore } from '@/store/truckVerified.js';

import { Button, Toast, Picker } from 'vant';
import TosUploader from '@/components/uploader/uploader.vue';

export default defineComponent({
  name: 'truck-step3',
  components: {
    Button,
    TosUploader,
    'van-picker': Picker
  },
  setup(_, ctx) {
    const userVerifiedStore = useTruckVerifiedStore();
    const file = ref([]);
    const FormObj = reactive({
      axles: {
        label: '车辆轴数',
        hidden: false,
        name: 'axles',
        rules: [
          { required: true, message: '请选择车辆轴数' },
          {
            message: '请选择正确的轴数',
            validator: () => {
              const { axles } = truckRef.value;
              const opts = axlesOptions.value;
              if (!opts?.find?.(it => it.text === axles)) return false;
              return true;
            }
          }
        ]
      }
    });
    const truckRef = computed(() => userVerifiedStore.verifiedInfo);
    onMounted(() => {
      if (userVerifiedStore?.verifiedInfo?.carWithOwnerUrl) {
        file.value = [
          {
            fileName: userVerifiedStore?.verifiedInfo?.carWithOwnerUrl || '',
            status: 'uploading',
            message: '加载中'
          }
        ];
      }
      if (truckRef.value.loadLength && truckRef.value.loadLength <= 4.2) {
        userVerifiedStore.updateInfo({
          axles: axlesOptions.value[0]?.text
        });
        validateForm('axles');
      } else if (truckRef.value.axles) {
        validateForm('axles');
      }
    });
    const validate = () => {
      if (!file.value.length || !file.value?.[0].fileName || file.value?.[0]?.status === 'loading') {
        Toast.fail('请上传图片');
        return false;
      }
      return true;
    };
    const validateForm = async (...params) => {
      const form = ctx.refs.form;
      let flag = true;
      if (form) {
        try {
          flag = await form.validate(...params);
          return true;
        } catch (e) {
          return false;
        }
      }
      return flag;
    };
    const handleToNext = async () => {
      const isValid1 = validate();
      const isValid2 = await validateForm();
      if (!isValid1 || !isValid2) return;
      userVerifiedStore.updateInfo({
        carWithOwnerUrl: file?.value[0]?.fileName || ''
      });
      if (ctx.root.$route.query.isEdit === 'true') {
        // true走更新 false 走添加接口
        userVerifiedStore.updateVerifiedInfo();
      } else {
        userVerifiedStore.submitVerifiedInfo();
      }
    };
    const popShow = ref(false);
    const axlesOptions = computed(() => {
      return userVerifiedStore.axlesOptions || [];
    });
    const onAxlesConfirm = ({ text }) => {
      userVerifiedStore.updateInfo({
        axles: text
      });
      popShow.value = false;
      validateForm('axles');
    };
    return {
      file,
      handleToNext,
      FormObj,
      truckRef,
      axlesOptions,
      onAxlesConfirm,
      popShow
    };
  }
});
</script>
<style scoped lang="less">
.owner-wrap {
  padding: 0.08rem 0.16rem;
  .owner-card {
    width: 3.27rem;
    height: 1.95rem;
    background: #f6f8fa;
  }
}
</style>
